import type { ReactNode } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { IS_BANNER_DISMISSABLE } from 'constants/internal';

interface Props {
  downloadUrl: string;
  bannerText: ReactNode;
  handleDismissBanner: () => void;
}

const PromoBanner = ({ downloadUrl, bannerText, handleDismissBanner }: Props) => {
  return (
    <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-gradient-to-r from-[#ff4d4d] to-[#f9cb28]  px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
      <div
        className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-logo-start to-logo-end  opacity-30"
          style={{
            clipPath:
              'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
          }}
        />
      </div>
      <div
        className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-logo-start to-logo-end  opacity-30"
          style={{
            clipPath:
              'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
          }}
        />
      </div>
      <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
        {bannerText}
        {downloadUrl && (
          <a
            href={downloadUrl}
            target="_blank"
            className="flex-none rounded-md bg-brand-primary px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-brand-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
          >
            Download now <span aria-hidden="true">&rarr;</span>
          </a>
        )}
      </div>
      <div className="flex flex-1 justify-end">
        {IS_BANNER_DISMISSABLE && (
          <button
            type="button"
            className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
            onClick={handleDismissBanner}
          >
            <span className="sr-only">Dismiss</span>
            <XMarkIcon className="h-8 w-8 text-gray-900" aria-hidden="true" />
          </button>
        )}
      </div>
    </div>
  );
};
export default PromoBanner;
