export const ROOT_PAGE = '/';
export const HOME_PAGE = ROOT_PAGE;
export const PROMPT_GENERATOR_PAGE = '/prompt-generator';
export const TRAIN_PAGE = '/train';
export const PLAY_PAGE = '/play';
export const EXPLORE_PAGE = '/explore';
export const ABOUT_PAGE = '/about';
export const BLOG_PAGE = '/blog';
export const FORGOT_PASSWORD_PAGE = '/forgot-password';
export const LOGOUT_PAGE = '/logout';
export const LOGIN_PAGE = '/login';
export const SIGNUP_PAGE = '/signup';
export const PERSONAL_ROUTES_ROOT = '/my';
export const QR_SHARE_PAGE = `${PERSONAL_ROUTES_ROOT}/share`; // OR SHOULD THIS JUST BE /share?
export const MY_PLAYERS_PAGE = `${PERSONAL_ROUTES_ROOT}/followers`; // OR SHOULD THIS JUST BE /calendar or /lessons?
export const MY_PROFILE_PAGE = `${PERSONAL_ROUTES_ROOT}/profile`; // OR SHOULD THIS JUST BE /calendar or /lessons?
export const MY_SETTINGS_PAGE = `${PERSONAL_ROUTES_ROOT}/settings`;
export const MY_PERSONAL_INFO_PAGE = `${PERSONAL_ROUTES_ROOT}/personal-details`;
export const MY_PAYMENT_DETAILS_PAGE = `${PERSONAL_ROUTES_ROOT}/payment-details`;
export const MY_NOTIFICATION_SETTINGS_PAGE = `${PERSONAL_ROUTES_ROOT}/notification-settings`;
export const MY_CHANGE_PASSWORD_PAGE = `${PERSONAL_ROUTES_ROOT}/change-password`;
export const CONTACT_US = `/contact`;
