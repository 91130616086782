import React, { useCallback, useEffect, useState } from 'react';
import { ListIds } from 'constants/email';
import { CHROME_EXTENSION_URL, IS_BANNER_ENABLED } from 'constants/internal';
import { PROMPT_GENERATOR_PAGE } from 'constants/pages';
import { useApiGateway } from 'hooks/useApi';
import Button from 'components/Button';
import Head from 'components/Head';
import Link from 'components/Link';
import PromoBanner from 'components/PromoBanner';
import classNames from 'styles/utils/classNames';

const HomeScreen = () => {
  const [email, setEmail] = useState('');
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const { post, isLoading, isSuccess } = useApiGateway('/v1/email-list');
  const isDisabled = isLoading;

  const [isBannerVisible, setIsBannerVisible] = useState(true);

  const handleDismissBanner = () => {
    setIsBannerVisible(false);
  };

  const BannerText = useCallback(() => {
    return (
      <p className="text-white">
        <strong className="font-bold">Chrome Extension</strong>
        <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
          <circle cx={1} cy={1} r={1} />
        </svg>
        <span className="font-medium">
          Get AI Mind directly in ChatGPT with the AI Mind Chrome extension
        </span>
      </p>
    );
  }, []);

  useEffect(() => {
    if (isSuccess && !isLoading) {
      setEmail('');
      setIsShowSuccess(true);
      setTimeout(() => {
        setIsShowSuccess(false);
      }, 3000);
    }
  }, [isSuccess, isLoading]);

  return (
    <>
      <Head title="AI Tools for Everyone" />
      {IS_BANNER_ENABLED && isBannerVisible && (
        <PromoBanner
          downloadUrl={CHROME_EXTENSION_URL}
          bannerText={<BannerText />}
          handleDismissBanner={handleDismissBanner}
        />
      )}
      <div className="relative min-h-screen">
        <img
          src="/images/landing-bg-4.png"
          className="absolute left-0 top-0 w-screen object-cover opacity-60"
        />
        <main className="relative z-10 px-6 pb-16">
          {/* <img
          src="/images/brain-bg.png"
          className="absolute left-0 top-0 w-64 rotate-180 opacity-10"
        />
        <img
          src="/images/brain-bg.png"
          className="absolute -right-[80px] top-[20%] w-[400px] -rotate-90 opacity-10"
        /> */}
          <div className="pt-24">
            <h1 className="bg-gradient-to-t from-logo-start to-logo-end bg-clip-text text-center font-title text-6xl font-bold text-transparent">
              AI Tools for Everyone
            </h1>
            <div className="mt-6 flex justify-center">
              <img src="/images/logo-vertical.svg" alt="hero" className="w-40" />
            </div>
            <h2 className="mx-auto mt-6 max-w-2xl text-center text-text-label-darkmode">
              Empowering everyone to harness the power of AI with intuitive tools and jargon-free
              education. Effortlessly.
            </h2>
          </div>
          <div className="mx-auto mt-24 max-w-4xl">
            <h2 className="font-title text-2xl font-semibold text-text-secondary-darkmode">
              AI Mind tools
            </h2>
            <div className="mt-4 grid grid-cols-1 gap-6 md:grid-cols-2">
              <div className="flex flex-col justify-between rounded-xl border border-white bg-bg-primary-darkmode bg-opacity-70 p-6">
                <div>
                  <h2 className="text-lg font-bold">Prompt Generator</h2>
                  <p className="mt-1 text-sm text-text-secondary-darkmode">
                    Effortlessly elevate your ChatGPT conversions with search engine-like
                    simplicity. Better prompts are just a click away - try it for FREE during beta!
                  </p>
                </div>
                <div className="flex justify-end">
                  <Link
                    href={PROMPT_GENERATOR_PAGE}
                    className="mt-5 inline-flex max-h-[3rem] w-full shrink-0 items-center justify-center whitespace-nowrap rounded-lg bg-brand-primary px-6 py-3 text-sm font-semibold text-white transition-all hover:shadow-md disabled:opacity-60"
                  >
                    Try Prompt Generator
                  </Link>
                </div>
              </div>
              <div className="flex flex-col justify-between rounded-xl border border-white bg-bg-primary-darkmode bg-opacity-70 p-6">
                <div>
                  <h2 className="text-lg font-bold">AI Mind Publication</h2>
                  <p className="mt-1 text-sm text-text-secondary-darkmode">
                    Learn, explore, or build the future of AI with top stories on the latest trends,
                    tools, and technology. Share your crazy success stories or AI-fueled fails in a
                    supportive community.
                  </p>
                </div>
                <div className="flex justify-end">
                  <Link
                    href="https://pub.aimind.so"
                    className="mt-5 inline-flex max-h-[3rem] w-full shrink-0 items-center justify-center whitespace-nowrap rounded-lg bg-brand-primary px-6 py-3 text-sm font-semibold text-white transition-all hover:shadow-md disabled:opacity-60"
                  >
                    View Publication
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="mx-auto mt-12 max-w-4xl pb-16">
            <h2 className="font-title text-2xl font-semibold text-text-secondary-darkmode">
              Stay up to date
            </h2>
            <div className="mt-4">
              <div className="rounded-xl border border-white bg-bg-primary-darkmode bg-opacity-70 p-6">
                <div>
                  <h2 className="text-lg font-bold">Join the AI Mind newsletter</h2>
                  <p className="mt-1 text-sm text-text-secondary-darkmode">
                    Receive the latest AI Mind news, free educational content, product launches, and
                    offers by joining our mailing list. We respect your inbox and won’t spam you.
                  </p>
                </div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    post({
                      payload: {
                        email,
                        listIds: [ListIds.BetaTesters],
                      },
                    });
                  }}
                  className="mt-3 flex flex-col space-y-2 sm:flex-row sm:space-x-2 sm:space-y-0"
                >
                  <input
                    placeholder="Your email..."
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={isDisabled}
                    className="w-full rounded-3xl border border-gray-800 px-5 py-3 text-sm text-text-primary-lightmode outline-none transition-shadow duration-200 ease-in-out focus:shadow-chat-box focus:outline-none disabled:opacity-60"
                  />
                  <Button
                    type="submit"
                    className={classNames(
                      'inline-flex w-full shrink-0 items-center justify-center whitespace-nowrap rounded-lg px-6 py-3 text-sm font-semibold text-white transition-all hover:shadow-md disabled:opacity-60 sm:w-56',
                      isShowSuccess ? 'bg-green-600' : 'bg-brand-primary',
                    )}
                    disabled={isDisabled}
                  >
                    {isShowSuccess ? 'Success!' : 'Join'}
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </main>
        <footer className="relative z-10 py-8 text-center text-sm text-text-secondary-darkmode">
          AI Mind Labs LLC © {new Date().getFullYear()}
        </footer>
      </div>
    </>
  );
};

export default HomeScreen;
